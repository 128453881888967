<template>
  <section>
    <div class="header-page">
      <div class="container">
        <h1 data-aos="fade-up">Prefeituras e Governos</h1>
        <p class="subtitulo" data-aos="fade-down">
          Sem essa de versão light. O mesmo AVA para rede pública e privada.
        </p>
      </div>
    </div>

    <div class="container page">
      <div class="text" data-aos="fade-down">
        <p>
          Um AVA 100% digital para aperfeiçoar o desempenho e aproximar alunos e
          professores, sendo um recurso poderoso para aprimorar os resultados
          das avaliações e do IDEB (Índice de Desenvolvimento da Educação
          Básica) do município. Nossa solução se adequa a qualquer material
          adquirido pela rede (editoras, sistemas de ensino, PNLD, material
          autoral, etc), oferecendo diversas ferramentas educacionais, design
          intuitivo e assessoria pedagógica. Apresenta um ambiente web
          responsivo que pode ser acessado de qualquer dispositivo móvel com
          acesso à internet, adaptando-se ao tamanho de tela, sem ocupar a
          memória de armazenamento do aparelho.
        </p>
        <div class="valores-empresa">
          <div class="valor-item">
            <h2>Principais benefícios:</h2>
            <p>
              <br />• Altamente escalável; <br />• O melhor custo-benefício do
              mercado; <br />• Ferramenta de autoria de jogos; <br />• Solução
              de avaliação continuada e metodologias ativas; <br />• Educador e
              aluno protagonista; <br />• Conteúdos e propostas alinhadas à
              BNCC; <br />• Assessoria de tecnologia educacional; <br />• Design
              segmentado por ciclo e idade; <br />• Customização em acordo com a
              sua marca ou com a nossa; <br />• Acervo com milhares de materiais
              e conteúdos inclusos; <br />• Dados inteligentes para gestão do
              município; <br />• Solução para melhorar o IDEB da rede.
            </p>
          </div>
          <div class="valor-item">
            <h2>Ideal para:</h2>
            <p>
              <br />• Secretarias de educação; <br />• Representantes e
              distribuidores de ofertas para o segmento público; <br />•
              Gestores educacionais da rede pública; <br />• Gestores que visam
              melhorar o desempenho educacional do município e estado.
            </p>
          </div>
        </div>
      </div>
      <div class="imagem">
        <img
          src="@/assets/img/prefeituras-e-governos.png"
          alt="sistema"
          class="foto-page"
          data-aos="fade-left"
        />
        <img
          src="@/assets/icons/detalhe_01.svg"
          class="detalhe"
          data-aos="fade-right"
        />
      </div>
    </div>
    <div class="container segmentos">
      <Carrosel :cards="cards" :segmentos="true">
        <div>
          <h2>Conheça <b>outros segmentos</b></h2>
          <p class="sub">
            Conheça mais sobre outros segmentos e descubra como podemos atuar na
            sua empresa.
          </p>
        </div>
      </Carrosel>
    </div>
  </section>
</template>

<script>
import Carrosel from "@/components/Carrosel";

export default {
  name: "Prefeitura",
  data() {
    return {
      cards: [
        {
          path: "icons/icon-distribuidores.svg",
          title: "Distribuidores",
          date: "",
          text: "A oferta digital mais criativa para o seu portfólio.",
          link: "/distribuidores",
        },
        {
          path: "icons/icon-editoras.svg",
          title: "Editoras",
          date: "",
          text: "O AVA com a cara da sua editora. Conectada, interativa e 100% digital.",
          link: "/editoras",
        },
        {
          path: "icons/icon-sistema.svg",
          title: "Sistema de ensino",
          date: "",
          text: "A solução mais prática para fidelizar alunos, professores e gestores ao seu sistema de ensino.",
          link: "/sistema-de-ensino",
        },
        {
          path: "icons/icon-empresas.svg",
          title: "Empresas e Promoções",
          date: "",
          text: "Marketing inteligente que fideliza e aumenta as suas vendas.",
          link: "/empresas-e-promocoes",
        },
        {
          path: "icons/icon-cooporativo.svg",
          title: "Corporativo/RH",
          date: "",
          text: "Estimule o desenvolvimento e potencial de seus colaboradores.",
          link: "/coportativo-rh",
        },
        {
          path: "icons/icon-cursos.svg",
          title: "Cursos e Ensino Técnico",
          date: "",
          text: "Qualidade, conteúdo e o engajamento que a sua instituição precisa em um só lugar!",
          link: "/cursos-e-ensino-tecnico",
        },
        {
          path: "icons/icon-universidade.svg",
          title: "Universidade",
          date: "",
          text: "Qualidade, conteúdo e o engajamento que a sua instituição precisa em um só lugar!",
          link: "/universidades",
        },
      ],
    };
  },
  components: {
    Carrosel,
  },
};
</script>
